import React, { Component } from "react";

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default  navbar-fixed-top" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">
              <span>
                <img src="img/logo64.png" width="50" height="50"  alt="Steve Kirsch Entertainment Logo" className="brand-logo" />
                <span className="brand-text">Steve Kirsch Entertainment</span>
              </span>
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li id="dropdown-button" className="dropdown">
              <a href="#dropdown-button" className="dropdown-toggle" data-toggle="dropdown">What I Do<b className="caret"></b></a>
                <ul className="dropdown-menu">
                  <li><a href="#livemusic" className="page-scroll">Live Music</a></li>
                  <li><a href="#originalmusic" className="page-scroll">Original Songs</a></li>
                  <li><a href="#trivianight" className="page-scroll">Trivia Night</a></li>
                  <li><a href="#weddings" className="page-scroll">Weddings</a></li>
                </ul>
              </li>
              <li>
                <a href="#about" className="page-scroll">
                  About Me
                </a>
              </li>
              <li>
                <a href="#portfolio" className="page-scroll">
                  Gallery
                </a>
              </li>
              <li>
                <a href="#testimonials" className="page-scroll">
                  Testimonials
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
