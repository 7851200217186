import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Gallery</h2>
            <p>
              I put my heart and soul into all of my work. You can see that shining through below.
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/fnl-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Friday Night Live</h4>
                      </div>
                      <img
                        src="img/portfolio/fnl-small.jpg"
                        className="img-responsive"
                        alt="Steve playing guitar and singing at an open mic night"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/studio-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Studio Time</h4>
                      </div>
                      <img
                        src="img/portfolio/studio-small.jpg"
                        className="img-responsive"
                        alt="Steve in his living room, pointing at the camera while recording a song"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/riverwalk-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Riverwalk Show</h4>
                      </div>
                      <img
                        src="img/portfolio/riverwalk-small.jpg"
                        className="img-responsive"
                        alt="Steve holding his guitar and smiling at the Clearfield Riverwalk"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/jb-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Jess &amp; Billy</h4>
                      </div>
                      <img
                        src="img/portfolio/jb-small.jpg"
                        className="img-responsive"
                        alt="Steve with Jess and Billy during their wedding"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/door-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Unlocking Potential</h4>
                      </div>
                      <img
                        src="img/portfolio/door-small.jpg"
                        className="img-responsive"
                        alt="A black and white shot of Steve leaning against an old door frame"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/dm-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Dan &amp; Molly</h4>
                      </div>
                      <img
                        src="img/portfolio/dm-small.jpg"
                        className="img-responsive"
                        alt="Steve with Dan and Molly during their wedding"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/steps-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Anything's Possible</h4>
                      </div>
                      <img
                        src="img/portfolio/steps-small.jpg"
                        className="img-responsive"
                        alt="Steve looks smolderingly into the camera while sitting on an old fire escape"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/wall-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Making Memories</h4>
                      </div>
                      <img
                        src="img/portfolio/wall-small.jpg"
                        className="img-responsive"
                        alt="Steve in a beanie smiling in front of his memory wall"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/scaffold-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Dream Building</h4>
                      </div>
                      <img
                        src="img/portfolio/scaffold-small.jpg"
                        className="img-responsive"
                        alt="Steve looking cool under some construction scaffolding"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
