import React, { Component } from "react";

export class features extends Component {
  render() {
    return (
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>What I Do</h2>
            <p>Click the icons below to learn more</p>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-xs-6 col-sm-6 col-md-3">
                    {" "}
                    <a href={d.link} className="page-scroll">
                      <i className={d.icon}></i>
                      <h3 className="features-link">{d.title}</h3>
                    </a>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    );
  }
}

export default features;
