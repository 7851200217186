import React, { Component } from 'react'

export class trivianight extends Component {
  render() {
    return (
        <div id="trivianight">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/trivia-night.jpg" className="img-responsive" alt="Steve and 3 of his friends enjoying a trivia night"/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="trivianight-text">
                <h2>Trivia Night</h2>
                <p>{this.props.data ? this.props.data.paragraph1 : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.paragraph2 : 'loading...'}</p>
                <div className="text-center">
                  <a
                    href="#contact"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Book Now
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default trivianight
