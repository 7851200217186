import React, { Component } from 'react'

export class weddings extends Component {
  render() {
    return (
        <div id="weddings">
        <div className="container">
          <div className="row">
          <div className="col-xs-12 col-md-6">
              <div className="weddings-text">
                <h2>Weddings</h2>
                <p>{this.props.data ? this.props.data.paragraph1 : 'loading...'}</p>
                <p>{this.props.data ? this.props.data.paragraph2 : 'loading...'}</p>
                <div className="text-center book-btn">
                  <a
                    href="#contact"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    More Info
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6"> <img src="img/weddings.jpg" className="img-responsive" alt="Steve walking up the asile after a wedding well done"/> </div>
          </div>
        </div>
      </div>
    )
  }
}

export default weddings
