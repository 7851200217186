import React, { Component } from 'react'

export class livemusic extends Component {
  render() {
    return (
        <div id="livemusic">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-9 embed-responsive embed-responsive-16by9"> <iframe title="Cover Songs" className="embed-responsive-item" src="https://www.youtube.com/embed/videoseries?list=PLm4Z48sqPPTQXeZYxkhNaIb5-32OlW-d9" allowFullScreen></iframe> </div>
            <div className="col-xs-12 col-md-3">
              <div className="livemusic-text">
                <h2>Live Music</h2>
                <p>{this.props.data ? this.props.data.paragraph1 : 'loading...'}</p>
                <div className="text-center">
                  <a
                    href="#contact"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Book Now
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default livemusic
